import { FollowButtonOutline } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { useTopicType } from "scmp-app/components/topic/hooks";
import type { topicHeaderTopic$key } from "scmp-app/queries/__generated__/topicHeaderTopic.graphql";

import {
  ChineseTitle,
  Container,
  Content,
  ImageIcon,
  Label,
  LabelContainer,
  StyledEntityFollowButton,
  StyledRelatedLinks,
  StyledSponsorTag,
  Title,
  TitleContainer,
  TopicContainer,
  TypeLabel,
  Wrapper,
} from "./styles";

type Props = {
  className?: string;
  reference: topicHeaderTopic$key;
};

export const TopicHeader: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const topic = useFragment(
    graphql`
      fragment topicHeaderTopic on Topic {
        ...topicHeaderRelatedLinksTopic
        ...entityFollowButtonBase
        ...hooksTopic
        mobileImage: image(type: MOBILE_APP_IMAGE) {
          alt
          title
          style(filter: { style: "300x300" }) {
            url
          }
        }
        name
        chineseName
        sponsor {
          ...sponsorTagSponsor
        }
      }
    `,
    reference_,
  );

  const iconImage = topic.mobileImage;

  const { currentType, hasSponsor } = useTopicType(topic);
  const isGeneralTopic = currentType === "general";
  const isShowImageIcon = !isGeneralTopic && !hasSponsor;

  return (
    <Container className={className}>
      <Wrapper $hasSponsor={hasSponsor}>
        {isShowImageIcon && iconImage?.style?.url && (
          <ImageIcon
            $aspectRatio="1"
            alt={iconImage.alt ?? iconImage.title ?? ""}
            lazyload={false}
            src={iconImage.style.url}
          />
        )}
        <Content>
          <LabelContainer $isGeneralTopic={isGeneralTopic}>
            <Label>TOPIC</Label>
            {!isGeneralTopic && (
              <TypeLabel>
                <span>&nbsp;/&nbsp;</span>
                {currentType}
              </TypeLabel>
            )}
          </LabelContainer>

          <TopicContainer>
            <TitleContainer>
              <Title>{topic.name}</Title>
              {topic.chineseName && <ChineseTitle>({topic.chineseName})</ChineseTitle>}
            </TitleContainer>
            <StyledEntityFollowButton
              outline={FollowButtonOutline.Outlined}
              reference={topic}
              source="Topic"
            />
          </TopicContainer>
        </Content>
        {topic.sponsor && (
          <StyledSponsorTag
            color="#A1A1A1"
            gap="0px"
            imageVariant="large"
            layoutDirection="vertical"
            nameColor="#000000"
            nameFontSize="inherit"
            nameLineHeight="inherit"
            reference={topic.sponsor}
            typeFontSize="inherit"
            typeLineHeight="inherit"
          />
        )}
      </Wrapper>
      <StyledRelatedLinks reference={topic} />
    </Container>
  );
};

TopicHeader.displayName = "TopicHeader";
